.footer {
  background-color: transparent;
  bottom: 0;
  margin: 0;
  padding: 0;
  position: relative;
  width: 100%;
  text-align: center;
  /* border-top: 0.3rem solid var(--primary-color); */
  font-family: var(--primary-font);
  color: var(--primary-font-color);
  font-size: 1.25rem;
}

@import url("https://fonts.cdnfonts.com/css/futura-std-4");

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Roboto"),
    url(./fonts/roboto/roboto-v20-latin-regular.woff2) format("woff2");
}

@font-face {
  font-family: Roboto;
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local("Roboto Italic"),
    url(./fonts/roboto/roboto-v20-latin-italic.woff2) format("woff2");
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local("Roboto Bold"),
    url(./fonts/roboto/roboto-v20-latin-700.woff2) format("woff2");
}

@font-face {
  font-family: RecoletaBlack;
  font-display: swap;
  src: url(./fonts/recoleta/RecoletaBlack.woff2) format("woff2");
}

@font-face {
  font-family: RecoletaSemiBold;
  font-display: swap;
  src: url(./fonts/recoleta/RecoletaSemiBold.woff2) format("woff2");
}

@font-face {
  font-family: RecoletaMedium;
  font-display: swap;
  src: url(./fonts/recoleta/RecoletaMedium.woff2) format("woff2");
}

:root {
  --bg-color: #001220;
  --primary-font-color: #fbe9d5;
  --nav-font-color: #fbe9d5;
  --nav-background-color: #8a2d27;
  --primary-color: #fbae3c; /* #f3c07f; */
  --secondary-color: #0e848c;
  --accent-color: #2c40eb;
  --nav-title-font: "Futura Std", sans-serif;
  --primary-font: "Roboto", Helvetica, sans-serif;
  --font-headlines-bold: "RecoletaBlack", "Roboto", Helvetica, sans-serif;
  --size-text-default: clamp(1rem, 6.66vw, 1.312rem);
  --size-text-small: 0.85rem;
  --size-text-medium: clamp(1.11rem, 6.66vw, 1.5rem);
  --size-text-large: clamp(1.666rem, 6.66vw, 2rem);
  --size-text-xlarge: clamp(2.5rem, 6.66vw, 3rem);
  --size-text-xxlarge: clamp(2rem, 10vw, 3.33rem);
  --size-text-huge: clamp(2.5rem, 15vw, 4.2rem);
  --nav-skew: skewY(-2deg);
}

::selection {
  background: var(--accent-color); /* WebKit/Blink Browsers */
}
::-moz-selection {
  background: var(--accent-color); /* Gecko Browsers */
}

body {
  margin: auto;
  background: var(--bg-color);
  background-image: url("./img/blob-background.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.page-container:after {
  background-image: url("./img/blob-background2.svg");
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.content-wrap {
  flex: 1;
}

.content {
  display: flex;
  align-items: center;
  margin: 0rem auto;
  max-width: 1080px;
}

.card-blur {
  box-shadow: 0 15px 25px var(--bg-color);
  border-radius: 5px;
  backdrop-filter: blur(14px);
  background-color: rgba(255, 255, 255, 0.09);
  padding: 0.5rem;
}

.logo-animated {
  position: relative;
  z-index: 1;
  transition: box-shadow 0.25s, top 0.25s, left 0.25s ease-out;
  border: 2px solid black;
  border-radius: 10px;
  box-shadow: 3px 5px black;
  top: 0;
  left: 0;
}

.logo-animated:hover {
  box-shadow: 0 0 0 0 black;
  top: 3px;
  left: 5px;
}

.button-animated {
  position: relative;
  z-index: 1;
  transition: box-shadow 0.25s, top 0.25s, left 0.25s ease-out;
  border: 2px solid black;
  border-radius: 10px;
  box-shadow: 3px 5px black;
  top: 0;
  left: 0;
}

.button-animated:hover {
  box-shadow: 0 0 0 0 black;
  top: 3px;
  left: 5px;
}

.button-animated.default {
  background-color: var(--primary-color);
  font-family: var(--primary-font);
  font-size: calc(1rem + 0.390625vw);
  padding: 0rem 1rem;
  height: 3rem;
}

h1 {
  font-family: var(--nav-title-font);
  font-size: var(--size-text-large);
  color: var(--primary-font-color);
}

h1.name {
  font-family: var(--font-headlines-bold);
  font-size: var(--size-text-huge);
  color: black;
  position: relative;
  top: 0;
  transition: top 0.35s, text-shadow 0.35s;
  -webkit-text-stroke: 0.05rem white;
  text-shadow: 0.3rem 0.3rem transparent;
}

h1.name:hover {
  top: -0.5rem;
  text-shadow: 0.4rem 0.4rem var(--primary-color);
}

h1.title {
  font-family: var(--font-headlines-bold);
  font-size: var(--size-text-huge);
  position: relative;
  top: 0;
  transition: top 0.35s, text-shadow 0.35s;
  -webkit-text-stroke: 0.1rem black;
  text-shadow: 0.3rem 0.3rem black;
}

p {
  font-family: var(--primary-font);
  font-size: var(--size-text-medium);
  color: var(--primary-font-color);
}

a {
  text-decoration: none;
  color: inherit;
}

a.text-link {
  top: 0;
  position: relative;
  color: var(--primary-color);
  transition: top 0.15s;
}

a.text-link:hover {
  top: -0.2rem;
  color: var(--primary-font-color);
  text-decoration: underline var(--primary-color);
}

.dynamic-words {
  --bg-size: 400%;
  --color-one: hsl(36, 78%, 46%);
  --color-two: hsl(61, 72%, 52%);
  background: linear-gradient(
      90deg,
      var(--color-one),
      var(--color-two),
      var(--color-one)
    )
    0 0 / var(--bg-size) 100%;
  color: transparent !important;
  background-clip: text;
  -webkit-background-clip: text;
  animation: move-bg 10s infinite linear;
}

@keyframes move-bg {
  to {
    background-position: var(--bg-size) 0;
  }
}

@import url(https://fonts.googleapis.com/css?family=Montserrat:800,200);

.scrolling-bg {
  --font-size: 280px;
  --bg-color: #1e2134;
  --text-color: #30442a;
  font-family: montserrat;
  overflow-x: hidden;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  background: var(--bg-color);

  line-height: 1.7;
  color: #fff;

  transform: skewY(-5deg);
}

.bg-text-container {
  transform: translateX(-50%);
  left: 50%;
  position: absolute;
  z-index: -999;
}

@keyframes text-scrolling {
  0% {
    transform: translate3d(-100%, 0, 0);
  }
  100% {
    transform: translate3d(0%, 0, 0);
  }
}

.animate-text {
  animation: text-scrolling 20s linear infinite;
  will-change: transform;
  display: block;
  position: relative;
  white-space: nowrap;

  &.left {
    animation-direction: reverse;
  }
}

.animate-text span {
  font-size: var(--font-size);
  color: transparent;
  -webkit-text-stroke: 2px var(--text-color);
  text-transform: uppercase;
  display: inline-block;
  line-height: 0.75;
  min-width: auto;
  font-weight: 800;

  &.filled {
    color: var(--text-color);
  }
}

/* .container {
  padding: 30px;
  max-width: 1000px;
  width: 100%;
  margin: 0 auto;
  .col {
    max-width: 600px;
    margin: 0;
  }
} */

.heading-scroll-bg {
  font-size: 90px;
  margin: 0;
}
.paragraph-scroll-bg {
  font-size: 18px;
  font-weight: 200;
  margin: 0;
}
